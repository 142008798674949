import React, { useEffect } from 'react';
import { ShiningText } from '../shining-text/ShiningText';
import { G1Links } from './utils';
import { useStyles } from './styles';

export const CarsTG = () => {
  const classes = useStyles();

  useEffect(() => {
    document.title = 'Cars (TELEGRAM)';
  }, []);

  return (
    <div>
      <div className={classes.root}>
        <ShiningText text='TELEGRAM POST' />
        <img
          loading='lazy'
          src='/assets/cars.webp'
          alt='Cars Telegram Channels and groups'
        />
        <span>
          Here are some descriptions for Telegram groups related to cars and
          Formula 1 racing: Cars Telegram Groups - Automotive Enthusiasts Hub: A
          community for car lovers to share their passion for automobiles, from
          vintage classics to cutting-edge supercars. Get updates on car
          launches, maintenance tips, and stunning car photography.
        </span>
        <span>
          Motorsport Mania: Dive into the world of high-speed action with
          discussions on rally racing, Formula 1, and other thrilling
          competitions. Perfect for fans of motorsports and adrenaline-pumping
          races.
        </span>
        <span>
          Luxury & Exotic Cars: Explore the world of high-end brands and
          supercars. Share your favorite models, discuss features, and dream big
          with fellow enthusiasts. Formula 1 Racing Telegram Groups
        </span>
        <ul>
          {cars.map(({ label, link }) => (
            <li>
              <G1Links link={link} label={label} />
            </li>
          ))}
        </ul>
        <img
          loading='lazy'
          src='/assets/cars_2.webp'
          alt='Cars Telegram Channels and groups'
        />
        <span>
          <p>
            F1 RaceFans: Stay updated with real-time news, interviews, and
            detailed analysis of each Grand Prix. Connect with other fans to
            discuss race strategies and driver performances.
          </p>

          <p>
            World Formula 1: A channel dedicated to the latest news, debates,
            and exclusive interviews with drivers and motorsport experts.
          </p>

          <p>
            F1 Full Races: Access full race replays, highlights, and
            behind-the-scenes content from the world of Formula 1. Let me know
            if you'd like more details or links to join these groups!
          </p>
        </span>
      </div>
    </div>
  );
};
const cars = [
  {
    label: 'Automotive Enthusiasts Hub',
    link: 'https://t.me/@fiaformula1',
  },
  {
    label: 'Motorsport Mania',
    link: 'https://t.me/@brandnew_cars',
  },
  {
    label: 'Luxury & Exotic Cars',
    link: 'https://t.me/@fdataan',
  },
];
