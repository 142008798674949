import React, { useEffect } from 'react';
import { ShiningText } from '../shining-text/ShiningText';
import { G1Links } from './utils';
import { useStyles } from './styles';

export const EducationTG = () => {
  const classes = useStyles();

  useEffect(() => {
    document.title = 'Education (TELEGRAM)';
  }, []);

  return (
    <div>
      <div className={classes.root}>
        <ShiningText text='TELEGRAM POST' />
        <img
          loading='lazy'
          src='/assets/edu.webp'
          alt='Education Telegram Channels and groups'
        />
        <span>
          Telegram education groups are online communities where students,
          educators, and enthusiasts come together to share knowledge,
          resources, and support. These groups often focus on specific topics
          like mathematics, science, history, or competitive exams. Members can
          access study materials, participate in discussions, and ask questions
          to clarify doubts. It's a collaborative learning environment that
          encourages sharing and interaction.
        </span>
        <ul>
          {education.map(({ label, link }) => (
            <li>
              <G1Links link={link} label={label} />
            </li>
          ))}
        </ul>
        <img
          loading='lazy'
          src='/assets/edu_2.webp'
          alt='Education Telegram Channels and groups'
        />
        <span>
          Some groups also provide tools for exam preparation, including
          quizzes, mock tests, and tips from experienced educators. They are a
          great way to stay updated, learn systematically, and connect with
          like-minded individuals. If you're interested, you can explore some
          popular Telegram education groups here or here.
        </span>
        <ul>
          {education_2.map(({ label, link }) => (
            <li>
              <G1Links link={link} label={label} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const education = [
  { label: 'Find Tech Jobs', link: 'https://t.me/@findtechjobs' },
  { label: 'Maths Group', link: 'https://t.me/@mathsgroup' },
  {
    label: 'Learn Science',
    link: 'https://t.me/@science',
  },
  { label: 'Quiz', link: 'https://t.me/@quizmasters' },
  {
    label: 'ON Philosophy',
    link: 'https://t.me/@on_philosophy',
  },
  { label: 'Psychology All', link: 'https://t.me/@psychologicall' },
  {
    label: 'Learn Japanese',
    link: 'https://t.me/@learn_japanese',
  },
  { label: 'Vedic Maths Club', link: 'https://t.me/@vedic_maths_club' },
  {
    label: 'Learn Android Easily',
    link: 'https://t.me/@learnandroideasily',
  },
  {
    label: 'Feed Brain',
    link: 'https://t.me/feedbrain',
  },
  {
    label: 'Perspective Mormon History',
    link: 'https://t.me/@perspectivemormonhistory',
  },
  {
    label: 'ENext',
    link: 'https://t.me/@enexteducation',
  },
  {
    label: 'Chessgram',
    link: 'https://t.me/@chessgram1',
  },
];

const education_2 = [
  {
    label: 'Did you really know',
    link: 'https://bestoftelegram.com/channels/Didyoureallyknow',
  },
  {
    label: 'Ask Me Now',
    link: 'https://bestoftelegram.com/channels/askmenow',
  },
  {
    label: 'Google Facts',
    link: 'https://bestoftelegram.com/channels/googlefactss',
  },
  {
    label: 'Shocking Fact',
    link: 'https://bestoftelegram.com/channels/ShockingFact',
  },
  {
    label: 'General KnowledgeTM',
    link: 'https://bestoftelegram.com/channels/General_KnowledgeTM',
  },
];
