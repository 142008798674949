import { makeStyles } from '@fluentui/react-components';

export const useStyles = makeStyles({
  root: {
    maxWidth: '500px',
    marginInline: 'auto',
    textAlign: 'center',
    margin: '1em',
    '&>img': {
      width: '95%',
    },
    '&>span, li': {
      textAlign: 'left',
      fontWeight: 500,
    },
    '& a': {
      color: 'rgb(0, 0, 238)',
      textDecorationLine: 'underline',
    },
  },
});
