import React, { useEffect } from 'react';
import { ShiningText } from '../shining-text/ShiningText';
import { G1Links } from './utils';
import { useStyles } from './styles';

export const BotsTG = () => {
  const classes = useStyles();

  useEffect(() => {
    document.title = 'Telegram Bots (TELEGRAM)';
  }, []);

  return (
    <div>
      <div className={classes.root}>
        <ShiningText text='TELEGRAM POST' />
        <img loading='lazy' src='/assets/tg_bots.webp' alt='Telegram Bots' />
        <span>
          <p>
            Telegram bots are automated programs or software integrated with the
            Telegram app, designed to perform specific functions and interact
            with users through predefined commands and messages. Here's an
            overview of Telegram bots and their uses:
          </p>
          <p>
            Automated Assistance: Bots can be used to automate tasks, such as
            answering FAQs, providing weather updates, or retrieving news
            articles.
          </p>
          <p>
            Content Delivery: Some bots specialize in delivering curated content
            like daily tips, jokes, or educational material to users directly in
            their chats.
          </p>
          <p>
            Productivity Tools: Many bots help with scheduling, reminders,
            translation, or document sharing, making them ideal for personal and
            professional use.
          </p>
          <p>
            Entertainment: Bots can host games, quizzes, or interactive stories,
            adding a fun element to chats.
          </p>
          <p>
            E-commerce: Businesses use bots for managing orders, customer
            support, and even payment processing via Telegram.
          </p>
          <p>
            Custom Solutions: Developers can create customized bots for unique
            use cases by using Telegram's Bot API.
          </p>
        </span>
        <ul>
          {bots_1.map(({ label, link }) => (
            <li>
              <G1Links link={link} label={label} />
            </li>
          ))}
        </ul>
        <img loading='lazy' src='/assets/tg_bots_2.webp' alt='Telegram Bots' />
        <span>
          <p>
            Telegram bots also play a significant role in fostering engagement
            within communities and groups. By enabling features such as polls,
            surveys, automated moderation, and personalized greetings, they help
            administrators manage large audiences effortlessly while keeping
            interactions dynamic and organized.
          </p>
          <p>
            Bots can even facilitate learning through interactive tutorials or
            quiz-based education, making them versatile tools for enhancing
            group activities. Their ability to adapt and cater to specific needs
            ensures they remain indispensable in varied scenarios, from hobbyist
            groups to professional networks.
          </p>
        </span>
        <ul>
          {bots_2.map(({ label, link }) => (
            <li>
              <G1Links link={link} label={label} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const bots_1 = [
  {
    link: 'https://t.me/@gmailbot',
    label: 'Gmail Bot',
  },
  {
    label: 'Hanabi Anibot',
    link: 'https://t.me/@hanabi_anibot',
  },
  {
    label: 'OP Man GANIME Bot',
    link: 'https://t.me/@opmanganimebot',
  },
  {
    link: 'https://t.me/@sagiribot',
    label: 'Sagiri Bot',
  },
  {
    link: 'https://t.me/@thefeedreaderbot',
    label: 'Thefeedreader Bot',
  },
  {
    label: 'Quadnite Bot',
    link: 'https://t.me/@quadnite_bot',
  },
  {
    link: 'https://t.me/@aniaartbot',
    label: 'Aniaart Bot',
  },
  {
    label: 'Deezer Music Bot',
    link: 'https://t.me/@deezermusicbot',
  },
  {
    label: 'Xiaomi Geeks Bot',
    link: 'https://t.me/@questable_bot',
  },
  {
    link: 'https://t.me/@mentionalertbot',
    label: 'Mentionalert Bot',
  },
  {
    label: 'Hungama TV Bot',
    link: 'https://t.me/@hungama_tv_bot',
  },
  {
    label: 'Reverse Search Bot',
    link: 'https://t.me/@reversesearchbot',
  },
  {
    label: 'GGBot',
    link: 'https://t.me/@ggbanbot',
  },
  {
    link: 'https://t.me/@oiwa_bot',
    label: 'Oiwa Bot',
  },
  {
    link: 'https://t.me/@instagramdlpro_bot',
    label: 'Instagramdlpro Bot',
  },
  {
    label: 'DerDiedas Quiz Bot',
    link: 'https://t.me/@derdiedas_quizbot',
  },
  {
    label: 'Ebook to Kindle Bot',
    link: 'https://t.me/@ebook_to_kindle_bot',
  },
  {
    label: 'Emilia Hikari Bot',
    link: 'https://t.me/@emiliahikaribot',
  },
  {
    link: 'https://t.me/@autoforwardbot',
    label: 'Autoforward Bot',
  },
  {
    label: 'Vindecoder Vehicle Bot',
    link: 'https://t.me/@vindecodervehiclebot',
  },
  {
    link: 'https://t.me/@marketzlivebot',
    label: 'Marketzlive Bot',
  },
  {
    link: 'https://t.me/@crosser_bot',
    label: 'Crosser Bot',
  },
  {
    label: 'Music Hunters Bot',
    link: 'https://t.me/@musichuntersbot',
  },
  {
    label: 'Sticker Download Bot',
    link: 'https://t.me/@stickerdownloadbot',
  },
  {
    label: 'Knight Robot',
    link: 'https://t.me/@knightrobot',
  },
  {
    label: 'Xiaomi Geeks Bot',
    link: 'https://t.me/@xiaomigeeksbot',
  },
  {
    label: 'GameBot',
    link: 'https://t.me/@gamebot',
  },
  {
    link: 'https://t.me/@mydramalistbot',
    label: 'Mydramalist Bot',
  },
  {
    label: 'TGMovies Bot',
    link: 'https://t.me/@tgmoviesbot',
  },
  {
    link: 'https://t.me/@s_and_l_bot',
    label: 'S_and_l Bot',
  },
  {
    label: 'Get IDs Bot',
    link: 'https://t.me/@getidsbot',
  },
  {
    label: 'Smokey Bot',
    link: 'https://t.me/@smokey_bot',
  },
  {
    label: 'Miss Rose Bot',
    link: 'https://t.me/@missrose_bot',
  },
  {
    link: 'https://t.me/@snapshot_bot',
    label: 'Snapshot Bot',
  },
  {
    label: 'BreachGuard Bot',
    link: 'https://t.me/@breachguardbot',
  },
  {
    link: 'https://t.me/@whoishostingbot',
    label: 'Whoishosting Bot',
  },
  {
    link: 'https://t.me/@nepgearbot',
    label: 'Nepgear Bot',
  },
  {
    label: 'Octanite Bot',
    link: 'https://t.me/@octanite_bot',
  },
  {
    label: 'Themer Bot',
    link: 'https://t.me/@themerbot',
  },
  {
    link: 'https://t.me/@sportpulsebot',
    label: 'Sportpulse Bot',
  },
];

const bots_2 = [
  {
    link: 'https://t.me/@babblerjsbot',
    label: 'Babblerjs Bot',
  },
  {
    link: 'https://t.me/@cheemsvcbot',
    label: 'Cheemsvc Bot',
  },
  {
    link: 'https://t.me/@teleformsbot',
    label: 'Teleforms Bot',
  },
  {
    link: 'https://t.me/@joinhider_freebot',
    label: 'Joinhider Freebot',
  },
  {
    link: 'https://t.me/@gruntlybot',
    label: 'Gruntly Bot',
  },
  {
    link: 'https://t.me/@balibirthdaybot',
    label: 'Balibirthday Bot',
  },
  {
    link: 'https://t.me/@yogawithadrienebot',
    label: 'Yogawithadriene Bot',
  },
  {
    link: 'https://t.me/@bookmediabot',
    label: 'Bookmedia Bot',
  },
  {
    link: 'https://t.me/@bawlbot',
    label: 'Bawl Bot',
  },
  {
    link: 'https://t.me/@nephobot',
    label: 'Nepho Bot',
  },
  {
    link: 'https://t.me/@harukaayabot',
    label: 'Harukaaya Bot',
  },
  {
    link: 'https://t.me/@streamradarbot',
    label: 'Streamradar Bot',
  },
  {
    link: 'https://t.me/@lcxrvsimgsrch_bot',
    label: 'Lcxrvsimgsrch Bot',
  },
  {
    link: 'https://t.me/@zumanjibot',
    label: 'Zumanji Bot',
  },
  {
    link: 'https://t.me/@torrenthuntbot',
    label: 'Torrenthunt Bot',
  },
  {
    link: 'https://t.me/@compilebot',
    label: 'Compile Bot',
  },
  {
    link: 'https://t.me/@newfileconverterbot',
    label: 'Newfileconverter Bot',
  },
  {
    link: 'https://t.me/@ivosportsbot',
    label: 'Ivosports Bot',
  },
  {
    link: 'https://t.me/@spacehuntbot',
    label: 'Spacehunt Bot',
  },
  {
    link: 'https://t.me/@unix_fortune_bot',
    label: 'Unix Fortune Bot',
  },
  {
    link: 'https://t.me/@gramchat_me_bot',
    label: 'Gramchat Me Bot',
  },
  {
    link: 'https://t.me/@converto_bot',
    label: 'Converto Bot',
  },
  {
    link: 'https://t.me/@rjailbreakbot',
    label: 'Rjailbreak Bot',
  },
  {
    link: 'https://t.me/@utube_dleder_bot',
    label: 'Utube Dleder Bot',
  },
  {
    link: 'https://t.me/@etoktobot',
    label: 'Etoktobot',
  },
  {
    link: 'https://t.me/@acutebot',
    label: 'Acutebot',
  },
  {
    link: 'https://t.me/@chtwrsbot',
    label: 'Chtwrs Bot',
  },
  {
    link: 'https://t.me/@airtrack_bot',
    label: 'Airtrack Bot',
  },
  {
    link: 'https://t.me/@mealschatbot',
    label: 'Mealschat Bot',
  },
  {
    link: 'https://t.me/@translatebot',
    label: 'Translate Bot',
  },
  {
    link: 'https://t.me/@lastfmrobot',
    label: 'Lastfm Robot',
  },
  {
    link: 'https://t.me/@channel2rssbot',
    label: 'Channel2rss Bot',
  },
  {
    link: 'https://t.me/@winepairbot',
    label: 'Winepair Bot',
  },
  {
    link: 'https://t.me/@pinterestdownloader_robot',
    label: 'Pinterestdownloader Robot',
  },
  {
    link: 'https://t.me/@rss_bot',
    label: 'Rss Bot',
  },
  {
    link: 'https://t.me/@getipdetails_bot',
    label: 'Getipdetails Bot',
  },
  {
    link: 'https://t.me/@pirela_bot',
    label: 'Pirela Bot',
  },
  {
    link: 'https://t.me/@apkdl_bot',
    label: 'Apkdl Bot',
  },
  {
    link: 'https://t.me/@dogememerbot',
    label: 'Dogememer Bot',
  },
  {
    link: 'https://t.me/@cat_memes_robot',
    label: 'Cat Memes Robot',
  },
  {
    link: 'https://t.me/@calendarinvitebot',
    label: 'Calendarinvite Bot',
  },
  {
    link: 'https://t.me/@usetgbot',
    label: 'Usetg Bot',
  },
  {
    link: 'https://t.me/@eddytravels_bot',
    label: 'Eddytravels Bot',
  },
  {
    link: 'https://t.me/@vangoghgo_bot',
    label: 'Vangoghgo Bot',
  },
  {
    link: 'https://t.me/@rottentomatoesrobot',
    label: 'Rottentomatoes Robot',
  },
];
