import React, { useEffect } from 'react';
import { ShiningText } from '../shining-text/ShiningText';
import { G1Links } from './utils';
import { useStyles } from './styles';

export const EntertainmentTG = () => {
  const classes = useStyles();

  useEffect(() => {
    document.title = 'Entertainment (TELEGRAM)';
  }, []);

  return (
    <div>
      <div className={classes.root}>
        <ShiningText text='TELEGRAM POST' />
        <img
          loading='lazy'
          src='/assets/entertainment.webp'
          alt='Cars Telegram Channels and groups'
        />
        <span>
          <p>
            <b>Browse these groups to find interesting content.</b>
          </p>
          <p>
            Memers Gallery: A hub for meme enthusiasts, featuring a collection
            of humorous and relatable content.
          </p>
          <p>
            Programmer Jokes: A group dedicated to light-hearted jokes and humor
            tailored for programmers.
          </p>
          <p>
            Telegram Android Talk: A community for Android enthusiasts to
            discuss and share insights about the platform.
          </p>
          <p>
            The Bugs: A group that likely focuses on quirky or interesting
            content, though specifics aren't detailed.
          </p>
        </span>
        <ul>
          {entertainment.map(({ label, link }) => (
            <li>
              <G1Links link={link} label={label} />
            </li>
          ))}
        </ul>
        <img
          loading='lazy'
          src='/assets/entertainment_2.webp'
          alt='Cars Telegram Channels and groups'
        />
        <span>
          <p>
            F1 RaceFans: Stay updated with real-time news, interviews, and
            detailed analysis of each Grand Prix. Connect with other fans to
            discuss race strategies and driver performances.
          </p>

          <p>
            World Formula 1: A channel dedicated to the latest news, debates,
            and exclusive interviews with drivers and motorsport experts.
          </p>

          <p>
            F1 Full Races: Access full race replays, highlights, and
            behind-the-scenes content from the world of Formula 1. Let me know
            if you'd like more details or links to join these groups!
          </p>
        </span>
        <ul>
          {entertainment_2.map(({ label, link }) => (
            <li>
              <G1Links link={link} label={label} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
const entertainment = [
  {
    label: 'Comedy Central Brasil',
    link: 'https://t.me/@comedycentralbr',
  },
  { label: 'Think Shorts', link: 'https://t.me/@thinkshorts' },
  {
    label: 'Top Documentary Films',
    link: 'https://t.me/@topdocumentaryfilms',
  },
  {
    label: 'Alex Turner Posting',
    link: 'https://t.me/@alexturnerposting',
  },
  { label: 'The Bugs', link: 'https://t.me/@thebugs' },
  { label: 'Tiik Tok', link: 'https://t.me/@tiikktok' },
  { label: 'Memers Gallery', link: 'https://t.me/@memers_gallery' },
  {
    label: 'Politically Incorrect Jokes',
    link: 'https://t.me/@politicallyincorrectjokes',
  },
  {
    label: 'Programmer Jokes',
    link: 'https://t.me/@programmerjokes',
  },
  { label: 'The Cuts', link: 'https://t.me/@thecuts' },
  { label: 'Babymetal', link: 'https://t.me/@babymetal3' },
  { label: 'Pictures Foider', link: 'https://t.me/@picturesfoider' },
  { label: 'F1 RaceFans', link: 'https://t.me/@dumpino' },
  { label: 'Greentext Memes', link: 'https://t.me/@greentextmemes' },
  {
    label: 'Mildly Interesting',
    link: 'https://t.me/@mildlyinteresting',
  },
  { label: 'Sakura Gakuin', link: 'https://t.me/@sakuragakuin' },
  { label: 'Austrian Guide', link: 'https://t.me/@austrian_guide' },
  { label: 'Shit Devs Say', link: 'https://t.me/@shitdevssay' },
];

const entertainment_2 = [
  {
    label: 'GIF Collection',
    link: 'https://t.me/@gifcollection',
  },
  {
    label: 'Funny',
    link: 'https://t.me/@funny',
  },
  {
    label: 'Sarcastic Society',
    link: 'https://t.me/@sarcastic_society',
  },
  {
    label: 'Wumo Channel',
    link: 'https://t.me/@wumo_channel',
  },
  {
    label: 'XKCD Channel',
    link: 'https://t.me/@xkcdchannel',
  },
  {
    label: 'Shitpost',
    link: 'https://t.me/@shitpost',
  },
  {
    label: 'Dilbert Scott Adams',
    link: 'https://t.me/@dilbert_scott_adams',
  },
  {
    label: 'Lavardos',
    link: 'https://t.me/@levardos',
  },
  {
    label: 'Heart Warming Memes',
    link: 'https://t.me/@heartwarmingmemes',
  },
];
